import httpManager from "../axios/httpManager";


export function getUserList(search, pageNum, pageSize, orderBy) {
    let url = '/user/user_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy;
    return httpManager.post(url, search);
}

export function saveUser(user) {

    return httpManager.post('/user/save', user);
}
export function updateSelectedUserStatus(record) {
    return httpManager.post('/user/update_selected_user_status', record);
}

export function getUserById(id) {
    return httpManager.get('/user/get_by_id?id=' + id + '&t=' + new Date().getTime())
}

export function updateUser(user) {
    return httpManager.post('/user/update', user);
}

export function updateUserCashAndPoint(user) {
    return httpManager.post('/user/update', user);
}

//회원상제정보용 메소드
export function getUserStatisticById(userId) {
    return httpManager.get('/user/user_statistic_by_id?userId=' + userId + '&t=' + new Date().getTime());
}

export function partnerInfoForUserPage(nickname) {
    return httpManager.post('/user/select_partner_info_for_user?nickName=' + nickname);
}

export function destoryUserSession(username) {
    return httpManager.get('/user/destory_usersession?username=' + username + '&t=' + new Date().getTime());
}

export function getCoinByUserId(userId) {
    return httpManager.get('/user/get_coin_amount?userId=' + userId + '&t=' + new Date().getTime())
}

export function getUserLoginFailLog(record, pageNum, pageSize) {
    return httpManager.post('/user/login_fail_list?' + 'pageNum=' + pageNum + '&pageSize=' + pageSize, record);
}


export function initUserData(userId, mode) {
    return httpManager.get('/common/init_user_data?id=' + userId + '&mode=' + mode + '&t=' + new Date().getTime())
}