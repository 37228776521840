import httpManager from "../axios/httpManager";

export function getReachBetlist(search, pageNum, pageSize) {
    let url = '/casino/reach/bet_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&t=' + new Date().getTime();
    return httpManager.post(url, search);
}

export function setReachBetResult(record) {
    let url = '/casino/reach/update_bet_result?t=' + new Date().getTime();
    return httpManager.post(url, record);
}