<template>
    <el-dialog :title="title"
               :visible.sync="outerVisible"
               @close="close"
               width="70%">

        <div style="display: flex;justify-content: space-between;align-items: flex-start; flex-wrap: nowrap">
            <div style="width: 49%;display: inline-block;text-align: center;">
                <p style="text-align: left" v-if="customer.user"> <span style="color: blue;cursor: pointer"
                                                                        @click="editUser(customer.userId,managerConst.MODE_EDIT)">[{{customer.user.nickname}}]</span> 님문의내용</p>
                <div class="direct-chat-text" style="margin-bottom: 10px" v-html="customer.content">

                </div>
                <div class="editor" style="padding: 5px">
                    <Toolbar
                            style="border-bottom: 1px solid #ccc"
                            :editor="editor"
                            :defaultConfig="toolbarConfig"
                            :mode="editormode"
                    />
                    <Editor
                            style="height: 300px; overflow-y: hidden;"
                            :value="editorhtml"
                            :defaultConfig="editorConfig"
                            :mode="editormode"
                            @onCreated="onEditorCreated"
                            @onChange="onEditorChange"
                    />
                </div>

                <el-button @click="updateReplay" type="primary" style="width: 90%!important;margin-top: 10px">답변하기</el-button>
            </div>
            <div style="width: 50%;;display: flex;justify-content: flex-start;align-items: flex-start;flex-wrap: wrap">
                <el-button type="success" size="mini" v-for="tmp in messageTempList" style="width: 30%;margin: 5px"
                           @click="setContentByTemplate(tmp)">
                    {{tmp.title}}
                </el-button>
            </div>
        </div>

        <div slot="footer" class="dialog-footer">
            <el-button @click="updateReplay()">저장하기</el-button>
            <el-button @click="outerVisible = false">취소</el-button>
        </div>
        <div style="width: 100%;margin-top: 10px">
            <sports-bet-info-comp :key="1000" :list="customer.betInfos"></sports-bet-info-comp>
            <leisure-game-bet-comp :key="1001" :list="customer.leisureGameBetInfos"></leisure-game-bet-comp>
        </div>
    </el-dialog>
</template>

<script>
    import managerConst from "../../../common/administrator/managerConst";
    import {getCusTempList} from "../../../network/manager/customerTemplateRequest";
    import UserInfoApplyComp from "../UserInfoApplyComp";
    import UserInfoCashPointLog from "../UserInfoCashPointLog";
    import UserInfoLoginLogComp from "../UserInfoLoginLogComp";
    import UserInfoCulCheckComp from "../UserInfoCulCheckComp";
    import UserInfoHonorCasinoBetComp from "../UserInfoHonorCasinoBetComp";
    import UserInfoTgame365BetComp from "../UserInfoTgame365BetComp";
    import {VueEditor} from "vue2-editor"
    import {getCustomerCetnerById, updateCustomerCetner} from "../../../network/manager/customercenterRequest";
    import {Editor, Toolbar} from '@wangeditor/editor-for-vue'

    import Vue from "vue";
    import UserEditToast from "./UserEditToast";
    import SportsBetInfoComp2 from "../../agent/SportsBetInfoComp2";
    import LeisureGameBetComp from "../LeisureGameBetComp";
    import SportsBetInfoComp from "../SportsBetInfoComp";
    Vue.prototype.$userEditToast = UserEditToast
    export default {
        name: "CustomerReplayToast",
        components: {
            SportsBetInfoComp,
            LeisureGameBetComp,
            SportsBetInfoComp2,
            UserInfoTgame365BetComp,
            UserInfoHonorCasinoBetComp,
            UserInfoCulCheckComp,
            UserInfoLoginLogComp,
            UserInfoCashPointLog, UserInfoApplyComp, VueEditor, Editor, Toolbar
        },
        data() {
            return {
                title: '',
                customerId: null, //
                customer: {contentReply: ''},
                contentReplay: '',
                closed: false,
                outerVisible: false,
                managerConst: managerConst,
                messageTempList: [],
                editor: null,
                editorhtml: '',
                toolbarConfig: {},
                editorConfig: {placeholder: '내용을입력하세요...'},
                editormode: 'default', // or 'simple'
            }
        },
        methods: {
            replay() {

            },
            close() {
                this.customer = {contentReply: ''}
                this.customerId = null
                this.closed = true
                this.outerVisible = false
            },
            destroyElement() {
                this.$destroy()
                this.$el.parentNode.removeChild(this.$el)
            },
            updateReplay() {
                this.customer.contentReply = this.editorhtml
                this.customer.status = managerConst.Customercenter.STATUS_REPLYED
                this.customer.display = managerConst.ENABLE
                updateCustomerCetner(this.customer).then(res => {
                    if (res.data.success) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: '답변 완료되였습니다'
                        });
                        this.close()
                        this.$bus.$emit('customerReplayComplate')
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 3000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })
            },
            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },
            onEditorCreated(editor) {
                this.editor = Object.seal(editor)
            },
            onEditorChange(editor) {
                this.editorhtml = editor.getHtml()
            },

            setContentByTemplate(temp) {
                this.editorhtml = temp.reply
            },
            beforeDestroy() {
                const editor = this.editor
                if (editor == null) return
                editor.destroy()
            },


        },
        created() {
            this.outerVisible = true
            getCusTempList({type: managerConst.CustomercenterTemplateType.TYPE_NORMAL}, 1, 100).then(res => {
                this.messageTempList = res.data.data
            })
            getCustomerCetnerById(this.customerId).then(res => {
                this.customer = res.data.data
                console.log(this.customer)
                if(this.customer.status == managerConst.Customercenter.STATUS_REPLYED){
                    this.editorhtml= this.customer.contentReply
                }
            })
        },
        mounted() {


        },
        watch: {
            closed(newVal) {
                if (newVal) {
                    this.customer = {}
                    this.outerVisible = false
                    this.destroyElement()
                }
            }
        },

    }
</script>

<style scoped>

    .edit_container {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
    .ql-editor{
        height:400px;
    }

    .table100 td {
        padding: 5px;
        background-color: #cfcfd1 !important;
    }

    .table100 th {
        width: 20%;
    }

    .table100 .el-input__inner {
        width: 80% !important;
    }

    .table_userstatisc {
        width: 100%;
        margin-bottom: 5px;
    }

    .table_userstatisc th {
        width: 14%;
    }

    .table_userstatisc td {
        background-color: #e8e8e8 !important;
        width: 14%;
    }


</style>