<template>
    <div style="display: flex;justify-content: space-between;align-items: flex-start;flex-wrap: wrap">
        <div style="width: 100%;display: flex">
            <div class="block">
                <span class="demonstration"></span>
                <el-date-picker size="mini"
                                v-model="startDate"
                                align="right"
                                type="date"
                                placeholder="시작일 선택"
                                :picker-options="pickerOptions"
                                @change="setStartDate">
                </el-date-picker>
            </div>
            <div style="padding-left: 2px;padding-right: 2px">~</div>
            <div class="block">
                <span class="demonstration"></span>
                <el-date-picker size="mini"
                                v-model="endDate"
                                align="right"
                                type="date"
                                placeholder="마감일 선택"
                                :picker-options="pickerOptions"
                                @change="setEndDate">
                </el-date-picker>
            </div>
            <el-button type="primary" @click="getCulChekInfo" size="mini" style="margin-left: 10px">검색</el-button>
        </div>

        <div style="width: 80%;min-height: 300px">

            <h5 style="background-color: #b3d8ff;line-height: 30px;padding: 0 5px">출석이벤트</h5>
            <table class="">
                <tr>
                    <td>
                        3일({{e3d}} 회),
                        7일({{e7d}} 회),
                        14일({{e14d}} 회),
                        21일({{e21d}} 회),
                        28일({{e28d}} 회),
                    </td>
                </tr>
            </table>
            <el-table
                    :data="logLoginEvent"
                    style="width: 100%"
                    max-height="730"
                    border>
                <el-table-column
                        label="날짜"
                        width="100">
                    <template slot-scope="scope">
                        {{scope.row.createTime|datef('MM-DD HH:mm')}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="충전"
                        width="200">
                    <template slot-scope="scope">
                        {{scope.row.rechargeSum|comma}}
                    </template>
                </el-table-column>

            </el-table>
            <table style="width: 300px">
                <tr>
                    <td style="width: 100px">총로그인: {{detailLogEventDays}} 일</td>
                    <td>총입금:
                        {{totalRecharge | comma}} 원
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    import managerConst from "../../common/administrator/managerConst";
    import {getLoginEventList} from "../../network/manager/logLoginRequest";

    export default {
        name: "UserInfoCulCheckComp",
        data() {
            return {
                startDate: '',
                endDate: '',
                managerConst: managerConst,
                totalRecharge: 0,
                e3d: 0,
                e7d: 0,
                e14d: 0,
                e21d: 0,
                e28d: 0,
                logLoginEvent: [],
                detailLogEventDays: 0,
                pickerOptions: {
                    shortcuts: [{
                        text: '오늘',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '어제',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '일주일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '15일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '30일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', date);
                        }
                    }]
                },
            }
        },
        props: {
            uid: {
                type: Number,
                default() {
                    return 0;
                }
            },

        },
        methods: {
            getCulChekInfo() {
                let s = {
                    'loginId': this.uid,
                    'loginType': managerConst.LogLogin.LOG_LOGIN_TYPE_USER,
                    'startDate': this.startDate,
                    'endDate': this.endDate
                }
                getLoginEventList(s, 1, 100).then(res => {
                    this.totalRecharge = 0;
                    this.logLoginEvent = res.data.data.list;
                    this.detailLogEventDays = this.logLoginEvent.length;
                    for (let i = 0; i < this.logLoginEvent.length; i++) {
                        this.totalRecharge += Number(this.logLoginEvent[i].rechargeSum);
                    }
                    this.e3d = res.data.data.e3d;
                    this.e7d = res.data.data.e7d;
                    this.e14d = res.data.data.e14d;
                    this.e21d = res.data.data.e21d;
                    this.e28d = res.data.data.e28d;
                })
            },
            setStartDate() {
                this.startDate = this.$moment(this.startDate).format('yyyy-MM-DD');
            },
            setEndDate() {
                this.endDate = this.$moment(this.endDate).format('yyyy-MM-DD')
            },
        },
        watch: {
            uid: {
                handler(newVal) {
                    if (newVal != null && newVal != 0) {
                        //this.startDate = this.$moment().subtract(1, 'days').format('yyyy-MM-DD')
                        this.startDate = this.$moment().startOf('month').format('YYYY-MM-DD')
                        this.endDate = this.$moment().format('yyyy-MM-DD')
                        this.getCulChekInfo();
                    }

                },
                immediate: true,
                deep: false
            },
        }

    }
</script>

<style scoped>

</style>