import httpManager from "../axios/httpManager";

export function getCusTempList (search, pageNum, pageSize) {
    return httpManager.post('/customercenter/template/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize, search);
}

export function saveCusTemp (template) {
    return httpManager.post('/customercenter/template/save', template);
}
export function updateCusTemp (template) {
    return httpManager.post('/customercenter/template/update', template);
}

export function getCusTempById (id) {
    return httpManager.get('/customercenter/template/get_by_id?id=' + id + '&t=' + new Date().getTime());
}

export function deleteCusTemp (id) {
    return httpManager.get('/customercenter/template/delete?id=' + id + '&t=' + new Date().getTime());
}