<template>
    <div>
        <div class="search">
            <div class="date_selector">
                <div class="block">
                    <span class="demonstration"></span>
                    <el-date-picker size="mini"
                                    style="width: 130px"
                                    v-model="startDate"
                                    align="right"
                                    type="date"
                                    placeholder="시작일"
                                    :picker-options="pickerOptions"
                                    @change="setStartDate">
                    </el-date-picker>
                </div>
                <div style="padding-left: 1px;padding-right: 1px;color: #cfcfd1">~</div>
                <div class="block">
                    <span class="demonstration"></span>
                    <el-date-picker size="mini"
                                    style="width: 130px"
                                    v-model="endDate"
                                    align="right"
                                    type="date"
                                    placeholder="마감일"
                                    :picker-options="pickerOptions"
                                    @change="setEndDate">
                    </el-date-picker>
                </div>
            </div>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">검색
            </el-button>
            <el-select v-model="tgame365Bet.betResult" @change="getTgameBetList" size="mini"
                       placeholder="결과선택"
                       style="width:100px;margin-left: 5px">
                <el-option key="13" label="전체" :value="null">전체</el-option>
                <el-option key="15" label="당첨" :value="managerConst.SportsBet.BET_RESULT_WIN">당첨</el-option>
                <el-option key="16" label="낙첨" :value="managerConst.SportsBet.BET_RESULT_LOSE">낙첨</el-option>
            </el-select>
            <el-select v-model="tgame365Bet.kind" @change="getTgameBetList" size="mini"
                       placeholder="게임선택"
                       style="width:120px;margin-left: 5px">
                <el-option label="전체" :value="null">전체</el-option>
                <el-option label="카지노" :value="managerConst.Tgame365.LEISURE_KIND_TGAME365_CASINO">카지노</el-option>
                <el-option label="슬롯" :value="managerConst.Tgame365.LEISURE_KIND_TGAME365_SLOT">슬롯</el-option>
                <el-option label="하이로" :value="managerConst.Tgame365.LEISURE_KIND_TGAME365_HILOW">하이로</el-option>
                <el-option label="룰렛" :value="managerConst.Tgame365.LEISURE_KIND_TGAME365_RUOLLET">룰렛</el-option>
                <el-option label="토큰휠" :value="managerConst.Tgame365.LEISURE_KIND_TGAME365_FW">토큰휠</el-option>
                <el-option label="달팽이 3줄" :value="managerConst.Tgame365.LEISURE_KIND_TGAME365_SNAIL3">달팽이 3줄</el-option>
                <el-option label="달팽이 4줄" :value="managerConst.Tgame365.LEISURE_KIND_TGAME365_SNAIL4">달팽이 4줄</el-option>
                <!--                        <el-option label="Fish입출금" :value="managerConst.Tgame365.LEISURE_KIND_TGAME365_FISH">Fish입출금</el-option>-->
            </el-select>
        </div>
        <div style="padding: 5px 0;font-size: 14px">
            <span style="padding-left: 20px;">총건수:{{totalBetCount|comma}}건</span>
            <span style="padding-left: 20px;">
                        총베팅금액:{{totalBetCash|comma}}원
                    </span>
            <span style="padding-left: 20px;">당첨금액:{{totalBetWinCash|comma}} 원 </span>
            <span style="padding-left: 20px;">손이익:{{(totalBetWinCash - totalBetCash)|comma}} 원 </span>
        </div>
        <div>
            <el-table
                    :data="tgame365BetList"
                    style="width: 100%"
                    max-height="750"
                    border>
                <el-table-column
                        fixed
                        label="트랜젝션 ID"
                        width="250">
                    <template slot-scope="scope">
                        {{scope.row.transactionid}}
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label="회차"
                        width="220">
                    <template slot-scope="scope">
                        {{scope.row.ground}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="gtype"
                        width="100">
                    <template slot-scope="scope">
                        {{scope.row.gtype}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="베팅"
                        width="80">
                    <template slot-scope="scope">
                        {{scope.row.betType}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="베팅금액"
                        width="100">
                    <template slot-scope="scope">
                        <span :class="{'text-blue':scope.row.amount>100000}">{{scope.row.amount | comma}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="베팅"
                        width="80">
                    <template slot-scope="scope">
                        {{scope.row.betType}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="베팅결과"
                        width="70">
                    <template slot-scope="scope">
                        <span v-if="scope.row.betResult == managerConst.SportsBet.BET_RESULT_WIN" class="badge badge-primary">당첨</span>
                        <span v-if="scope.row.betResult == managerConst.SportsBet.BET_RESULT_LOSE" class="">낙첨</span>
                        <span v-if="scope.row.betResult == managerConst.SportsBet.BET_RESULT_CANCEL" class="badge badge-danger">취소</span>
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label="베팅시간"
                        width="125">
                    <template slot-scope="scope">
                        {{scope.row.createTime | datef('MM월DD일 HH:mm:ss')}}
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label="정산"
                        width="80">
                    <template slot-scope="scope">
                        <span v-if="scope.row.givebacked == managerConst.YES">완료</span>
                    </template>
                </el-table-column>

                <el-table-column
                        label="action"
                        width="250">
                    <template slot-scope="scope">
                        <el-button size="mini" type="warning" v-if="scope.row.kind != managerConst.LeisureGame.LEISURE_KIND_TGAME365_FISH && scope.row.betResult == managerConst.SportsBet.BET_RESULT_WAITING"
                                   @click="resetResult(scope.row.id)">Reset
                        </el-button>
                        <el-button size="mini" type="danger" v-if="scope.row.kind != managerConst.LeisureGame.LEISURE_KIND_TGAME365_FISH && scope.row.betResult == managerConst.SportsBet.BET_RESULT_WAITING"
                                   @click="manualResult(scope.row.id,managerConst.SportsBet.BET_RESULT_LOSE,'낙첨')">낙첨
                        </el-button>
                        <el-button size="mini" type="danger" v-if="scope.row.kind != managerConst.LeisureGame.LEISURE_KIND_TGAME365_FISH && scope.row.betResult == managerConst.SportsBet.BET_RESULT_WAITING"
                                   @click="manualResult(scope.row.id,managerConst.SportsBet.BET_RESULT_CANCEL,'취소')">취소
                        </el-button>
                    </template>
                </el-table-column>


            </el-table>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[15, 50, 100, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>

</template>

<script>
    import managerConst from "../../common/administrator/managerConst";

    import DateSelectorComp from "./DateSelectorComp";
    import {getLeisureGameBetStatisticByAttribute, getLeisureGameList} from "../../network/manager/leisureBetRequest";
    import {getHonorCasinoBetlist} from "../../network/manager/honorCasinoRequest";
    import {getTgameBetlist, manualTgameResult, resetTgameResult} from "../../network/manager/tgame365Request";
    import {Loading} from "element-ui";


    export default {
        name: "UserInfoTgame365BetComp",
        components: {DateSelectorComp},
        data() {
            return {
                startDate: '',
                endDate: '',
                tgame365Bet: {partnerId: null, user: {}},
                tgame365BetList: [],
                pageNum: 1,
                pageSize: 15,
                pageTotal: 0,
                totalBetCount: 0,
                totalBetCash: 0,
                totalBetWinCash: 0,
                managerConst: managerConst,
                pickerOptions: {
                    shortcuts: [{
                        text: '오늘',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '어제',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '일주일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '15일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
                            picker.$emit('pick', date);
                        }
                    }]
                },
            }
        },
        props: {
            uid: {
                type: Number,
                default() {
                    return 0;
                }
            },

        },
        methods: {
            search() {
                this.getTgameBetList();
            },
            getTgameBetList() {
                this.tgame365Bet.beginTime = this.startDate
                this.tgame365Bet.endTime = this.endDate
                getTgameBetlist(this.tgame365Bet, this.pageNum, this.pageSize).then(res => {
                    this.pageTotal = res.data.data.pageTotal;
                    this.tgame365BetList = res.data.data.list;
                    this.totalBetCount = res.data.data.totalcount;
                    this.totalBetCash = res.data.data.betTotal;
                    this.totalBetWinCash = res.data.data.winTotal;
                })

            },
            resetResult(id){
                this.$confirm('토큰베팅내역을 초기화 하시껬습니까?', '초기화', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let loadingInstance = Loading.service({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    resetTgameResult(id).then(res=>{
                        this.getTgameBetList()
                        loadingInstance.close()
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '초기화 취소되였습니다'
                    });
                });
            },
            manualResult(id,betResult,txt){
                this.$confirm('번호:' + id + '내역 결과를 ' + txt + '적용 하시겠습니까?', '초기화', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let loadingInstance = Loading.service({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    manualTgameResult(id,betResult).then(res=>{
                        this.getTgameBetList()
                        loadingInstance.close()
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '취소되였습니다'
                    });
                });
            },

            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.search();
            },
            setStartDate(date) {
                this.startDate = this.$moment(date).format('yyyy-MM-DD');
            },
            setEndDate(date) {
                this.endDate = this.$moment(date).format('yyyy-MM-DD')
            },
            setPartnerId(parterId) {
                this.leisureGameBet.partnerId = parterId
                this.search();
            },
            leisureRefresh() {
                this.search();
            }
        },
        created() {

        },
        watch: {
            uid: {
                handler(newVal) {
                    if (newVal != null && newVal != 0) {
                        this.startDate = this.$moment().format('yyyy-MM-DD')
                        this.endDate = this.$moment().format('yyyy-MM-DD')
                        this.tgame365Bet.userId = this.uid
                        this.search()
                    }
                },
                immediate: true,
                deep: false
            },
        }
    }
</script>

<style scoped>

</style>