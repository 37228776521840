import httpManager from "../axios/httpManager";


export function getTgameBetlist (search, pageNum, pageSize) {
    let url = '/tgame365/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}
export function resetTgameResult (id) {
    let url = '/tgame365/reset_result?id=' + id + '&t=' + new Date().getTime();
    return httpManager.get(url);
}
export function manualTgameResult (id, betResult) {
    let url = '/tgame365/manual_result?id=' + id + '&betResult=' + betResult + '&t=' + new Date().getTime();
    return httpManager.get(url);
}
export function cancelTgameResult (id) {
    let url = '/tgame365/cancel_result?id=' + id + '&t=' + new Date().getTime();
    return httpManager.get(url);
}