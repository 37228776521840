import httpManager from "../axios/httpManager";

export function getLoginLogList (search, pageNum, pageSize) {
    let url = '/log/login'+'?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}

export function getLoginEventList  (search, pageNum, pageSize) {
    let url = '/log/loginEvent'+'?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}